<template src="./orderForm.html"></template>

<script>
//import working_dates from "@/assets/db_orderFormDates.json"
export default {
  name: 'orderForm',
  data: () => ({
    rules: [
      (value) => !!value || 'Обязательно',
      (value) => {
        const pattern = /^.*[^A-zА-яЁё. ].*$/;
        return !pattern.test(value) || 'Должны быть только буквы';
      },
      (value) =>
        (value && value.length >= 3) || 'Должно быть минимум 3 символа',
    ],
    emailRules: [
      (v) => !!v || 'E-mail обязателен',
      (v) => /.+@.+\..+/.test(v) || 'Некорректный E-mail',
    ],
    alert: {
      data: {
        type: 'info',
        text: 'Заполните все поля для перехода к оплате',
      },
      flags: {
        error: false,
        success: false,
        info: true,
      },
      alerts: {
        error: 'Что то пошло не так, попробуйте еще раз',
        success: 'Форма заполнена, можно приступить к оплате',
        info: 'Заполните все поля для перехода к оплате',
      },
    },
    flags: {
      loadedGroup:false,
      loadedDates:false,
      error: false,
      success: false,
      info: true,
    },
    alerts: {
      error: 'Что то пошло не так, попробуйте еще раз',
      success: 'Форма заполнена, можно приступить к оплате',
      info: 'Заполните все поля для перехода к оплате',
    },
    date: new Date(),
    raduga: {
      active: false,
      menu: false,
      name: 'Суббота',
      minDate: new Date().toISOString().slice(0, 10),
      dateDays: new Date().toISOString().substr(0, 10),
      // foreWeak: [],
      dates:[
          new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
          new Date(new Date().getFullYear(), new Date().getMonth() + 2, 1)
      ],
      days: ["Sat"]
    },
    db_date: new Date().toISOString().slice(0, 10),
    inputsList: {
      db_parent: {
        id: 'db_parent',
        show: true,
        name: 'ФИО родителя',
        type: 'input',
        value: '',
        placeholder: '',
        required: true,
        icon: 'mdi-account-multiple',
        col: 6,
      },
      db_mail: {
        id: 'db_mail',
        show: true,
        name: 'E-mail',
        type: 'input',
        value: '',
        placeholder: '',
        required: true,
        icon: 'mdi-email-fast-outline',
        col: 6,
      },
      db_ch_name: {
        id: 'db_ch_name',
        show: true,
        name: 'Имя ребенка',
        type: 'input',
        value: '',
        placeholder: '',
        required: true,
        icon: 'mdi-account-child-outline',
        col: 4,
      },
      db_ch_family: {
        id: 'db_ch_family',
        show: true,
        name: 'Фамилия ребенка',
        type: 'input',
        value: '',
        placeholder: '',
        required: true,
        icon: 'mdi-account-child-outline',
        col: 4,
      },
      db_ch_father: {
        id: 'db_ch_father',
        show: true,
        name: 'Отчество ребенка',
        type: 'input',
        value: '',
        placeholder: '',
        required: true,
        icon: 'mdi-account-child-outline',
        col: 4,
      },
      db_sum: {
        id: 'db_sum',
        show: true,
        name: 'Сумма',
        type: 'input',
        value: '0',
        placeholder: '',
        required: false,
        icon: 'mdi-cash',
        col: 6,
      },
      db_mounth: {
        id: 'db_mounth',
        show: true,
        name: 'Месяц',
        type: 'select',
        menu: false,
        modal: false,
        minDate: new Date().toISOString().slice(0, 10),
        value: new Date().toISOString().substr(0, 7),
        placeholder: '',
        required: false,
        icon: 'mdi-phone',
        col: 6,
        options: [],
      },
      db_group: {
        id: 'db_group',
        show: true,
        name: 'Коллектив',
        type: 'select',
        value: '',
        placeholder: '',
        required: true,
        icon: 'mdi-account-group-outline',
        col: 12,
        options: [],
      },
    },
    emptyChildren: [],
    children: [],
    childFIO: ['', '', ''],
    childReady: false,
    orderData: {
      pant: '',
      child: '',
      mail: '',
      price: '',
      date: '',
      group: '',
      subGroup: '',
    },

    payment_created: false,
    query_id: 0,
    payment_id: 0,
    bank_link: 0,

    snackbar: false,
    snack_text: '',

    valid: false,
    working_dates:{
      month: {
        start: "2023-04-01",
        end: "2023-12-31",
        startActive: false,
        endActive: false
      },
      saturday: {
        start: "2023-12-31",
        end: "2023-12-31",
        startActive: false,
        endActive: false
      },
      team:{
        raduga:{
          days:{
            saturday: {
              start: "2023-12-31",
              end: "2023-12-31",
              startActive: false,
              endActive: false
            },
            tuesday: {
              start: "2023-12-31",
              end: "2023-12-31",
              startActive: false,
              endActive: false
            },
          }
        }
      }
    }
  }),

  created() {
    this.groupLoad();

    this.datesLoad();


    // if(working_dates.b){
    //   this.working_dates = working_dates.data
    // }
  },

  computed: {
    getStartDate() {
      if(this.working_dates.month.startActive) return this.working_dates.month.start
      // var startDate = new Date(
      //     this.date.getFullYear(),
      //     this.date.getMonth() - 2,
      //     10
      // );
      // return startDate.toISOString().slice(0, 10);
      return "2023-04-01";
    },
    getEndDate() {
      if(this.working_dates.month.endActive) return this.working_dates.month.end
      var endDate = new Date(
        this.date.getFullYear(),
        this.date.getMonth() + 5,
        10
      );
      return endDate.toISOString().slice(0, 10);
      // return "2023-12-31"
    },
    // TODO Сделать учет групп по id/названию и разных дней, а не только субботы
    // сейчас хардкод
    getStartDateDays() {
      if(this.working_dates.team.raduga.days.saturday.startActive) return this.working_dates.team.raduga.days.saturday.start
      var startDate = new Date(
        this.date.getFullYear(),
        this.date.getMonth() - 1,
        this.date.getDay()
      );
      return startDate.toISOString().slice(0, 10);
      // return "2023-12-31"
    },
    getEndDateDays() {
      if(this.working_dates.team.raduga.days.saturday.endActive) return this.working_dates.team.raduga.days.saturday.end
      var endDate = new Date(
        this.date.getFullYear(),
        this.date.getMonth() + 2,
        1
      );
      return endDate.toISOString().slice(0, 10);
      // return "2023-12-31"
    },
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },

    allowedDaysNew(val){
      let date = new Date(val).toString().substr(0, 3)
      // if(date === 'Sat') return val
      if (
        (date === 'Sat') ||
        (date === 'Tue')
      )
        return val
    },

    getNextSatSelect(date = new Date()){
      let sat = new Date(date.setDate(date.getDate() + ((7 - date.getUTCDay() + 6) % 7 || 7))).toISOString().slice(0, 10)
      // let tue = new Date(date.setDate(date.getDate() + ((7 - date.getUTCDay() + 2) % 7 || 7))).toISOString().slice(0, 10)
      return sat
    },

    datesLoad() {
      // console.log('start');
      let pr2 = this.$store.state.api_dk.getJSON("/dk/db_orderFormDates2.json");
      pr2.then((data) => {
        //console.log(data);
        if(data.b){
          this.working_dates = data.data
          this.flags.loadedDates = data.b
        }
      })
    },

    groupLoad() {
      // console.log('start');
      let pr2 = this.$store.state.api_dk.LoadTeam();
      pr2.then((data) => {
        this.inputsList.db_group.options = data;
        this.flags.loadedGroup = true;
      })
    },
    subGroup(type) {
      let item = this.inputsList.db_group.options.find(
        (el) => el.id === this.orderData.group
      );
      let typeGroup, child;

      switch (type) {
        case 'child':
          child = this.children.find((el) => el.id === this.orderData.subGroup);
          typeGroup = child.type;
          break;
        default:
          typeGroup = item.type;
      }
      switch (typeGroup) {
        case 1://Коллектив без групп
          this.children = [];
          this.childReady = false;
          // this.orderData.price = item.price;
          this.orderData.subGroup = 0;
          if (item.id === 26 && item.name === 'Радуга') {
            this.orderData.date = this.getNextSatSelect();
            this.raduga.active = true;
          } else {
            this.raduga.active = false;
          }
          this.priceFind()
        break;
        case 2://Коллектив с группами
          this.children = [];
          this.children = item.child;
          this.childReady = true;
          this.orderData.price = '';
          //this.orderData.date = "";
          this.raduga.active = false;
        break;
        case 3://Группа коллектива
          // this.orderData.price = child.price;
          // console.log(child.id)
          this.priceFind()
        break;
        default:
          console.log('Чет не нашлось');
          this.childReady = false;
          this.raduga.active = false;
      }
    },
    flagsChecker(text) {
      for (let key in this.alert.flags) {
        this.alert.flags[key] = false;
      }
      switch (text) {
        case 'success':
          this.alert.flags.success = true;
          this.alert.data.text = this.alert.alerts.success;
          this.alert.data.type = text;
          break;
        case 'error':
          this.alert.flags.error = true;
          this.alert.data.text = this.alert.alerts.error;
          this.alert.data.type = text;
          break;
        default:
          this.alert.flags.inform = true;
          this.alert.data.text = this.alert.alerts.info;
          this.alert.data.type = 'info';
      }
    },
    priceFind(){
      //Первый запуск функции делаем когда меняем коллектив,
      //Если даты нет, то на этом и заканчиваем
      //если дата есть, но группа type=2, эта функция не вызывается, только удаляется цена
      //если дата есть, и группа type=1 или type=3
      //проходим и в зависимости от флага childReady - определеяем откуда взять id для запроса цены
      //Второй запуск происходит при смене даты
      if(this.orderData.date === '') return

      let id
      let date = this.orderData.date
      switch(this.orderData.date.length){
        case 7:
          date += '-01%2000:00:02'
          break;
        case 10:
          date += '%2000:00:02'
          break;
      }

      id = this.childReady ? this.orderData.subGroup : this.orderData.group
      if(id === '') return;

      let pr2 = this.$store.state.api_dk.FindTeamPriceDay(id,date);
      pr2.then((data) => {
        this.orderData.price = data.price;
        this.formChecker()
      })
    },
    formChecker(e) {
      console.log(e);
      // console.log(this.orderData)
      let allCheck = true;
      let error = false;
      let valid = /.+@.+\..+/;

      for (let key in this.orderData) {
        if (this.orderData[key] === '') {
          allCheck = false;
        }
        switch (key) {
          case 'pant':
          case 'child':
            this.orderData[key] = this.orderData[key].trim();
            break;
          case 'mail':
            this.orderData[key] = this.orderData[key].trim();
            if (
              !valid.test(this.orderData[key]) &&
              this.orderData[key].length > 0
            ) {
              allCheck = false;
              error = true;
            }
            break;
        }
      }

      if (!this.orderData.price > 0) {
        allCheck = false;
      }

      if (allCheck && !error) {
        this.flagsChecker('success');
      } else if (!allCheck && error) {
        this.flagsChecker('error');
      } else {
        this.flagsChecker();
      }
    },

    goToOrder() {

      // window.location.href = "/stop";

      console.log(
        'Пока отрабатываем ошибку, но она нужна на случай если не получилось отправить пользователя на оплату'
      );
      console.log(this.orderData);

      console.log(this.orderData.date);
      console.log(this.orderData.date.length);

      let date_arr = this.orderData.date.split('-');
      if (this.orderData.date.length === 7) {
        date_arr.push('01');
      }

      let team = this.orderData.subGroup;
      if (team === 0) {
        team = this.orderData.group;
      }

      let query_data = {
        data: {
          base: {
            parent: this.orderData.pant,
            children: this.orderData.child,
            email: this.orderData.mail,
            year: date_arr['0'],
            month: date_arr['1'],
            day: date_arr['2'],
            team: team,
          },
        },
      };

      console.log(query_data);

      let pr2 = this.$store.state.api_dk.AddQuery(query_data);
      pr2.then((data) => {
        console.log(data)
        if (data.b) {
          this.query_id = data.id;

          let payment_data = {
            data: {
              base: {
                query: this.query_id,
              },
            },
          };
          let pr3 = this.$store.state.api_dk.AddPayment(payment_data);
          pr3.then((data2) => {
            console.log(data2);
            if (data2.b) {
              this.payment_id = data2.id;
              this.bank_link = data2.bank_link;

              this.snack_text =
                  'Платеж #' + this.payment_id + ' Переходим в банк';
              this.snackbar = true;

              this.payment_created = true;

              setTimeout(function () {
                window.location.href = data2.bank_link;
              }, 3 * 1000);

              //window.location.href = data2.bank_link;
            }
          })
        }
      })
    },
  },
  watch: {
    childFIO: function () {
      // watch it
      if (
        this.childFIO[0].length > 3 &&
        this.childFIO[1].length > 2 &&
        this.childFIO[2].length > 3
      ) {
        console.log(this.childFIO);
        this.orderData.child =
          this.childFIO[0].trim() +
          ' ' +
          this.childFIO[1].trim() +
          ' ' +
          this.childFIO[2].trim();
      } else {
        this.orderData.child = '';
      }
      // this.orderData.child =
    },
  },
};
</script>

<style scoped>
.r {
}
</style>
